import styled from "styled-components"

const Tag = styled.small`
  display: inline-block;
  line-height: 1em;
  padding: 0.8ch;
  border-radius: 0.8ch;
  cursor: default;
  color: deeppink;
  background-color: rgba(255, 20, 147, 0.25);
`

export default Tag
