import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

import styled from "styled-components"
import Tag from "../components/Tag"
import { TextContainer } from "../components/Container"

const BlogPostWrapper = styled.div`
  margin: 7px auto;
  display: grid;
  grid-gap: 1rem;
`

const BlogPage = ({ data: { allMarkdownRemark } }) => {
  const blogPosts = allMarkdownRemark.edges.map(({ node }) => node)
  return (
    <Layout>
      <SEO title="Blog" />
      <h1 style={{ textAlign: "center" }}>Most Recent Blog Posts</h1>
      <TextContainer>
        <BlogPostWrapper>
          {blogPosts.map(({ frontmatter, timeToRead, excerpt }) => (
            <div>
              <h2>
                <Link to={`blog/${frontmatter.slug}`}>{frontmatter.title}</Link>
              </h2>
              <p>
                {frontmatter.topics.map(topic => [
                  <Tag key={topic}>{topic}</Tag>,
                  " ",
                ])}
                ∙{" "}
                {`${timeToRead} ${
                  timeToRead === 1 ? "min" : "mins"
                } reading time`}
              </p>
              <p>{excerpt}</p>
            </div>
          ))}
        </BlogPostWrapper>
      </TextContainer>
    </Layout>
  )
}

export const blogPageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { fields: frontmatter___date_published, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date_published
            slug
            topics
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`

export default BlogPage
